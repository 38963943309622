<template>
    <div>
        <RelatorioPrincipal/>
    </div>
</template>

<script>
import {getUser} from "@/services/api";

import RelatorioPrincipal from '@/components/RelatorioPrincipal.vue';

export default {
    name: "Dashboard",
    components: {RelatorioPrincipal},
    data(){
        return{
            user: {},
        }
    },
    created(){
        this.user = getUser()
    },
    methods:{
    }
}
</script>

<style scoped>

</style>
